import PropTypes from 'prop-types';
import {useEffect, useState, useContext} from 'react';
import {UserDetailsContext} from 'src/contexts/user-details-context';
import {ViewFeatureError} from 'src/pages/error/view';

// Importer ou définir la config des features
const featuresConfig = {
  features: {
    wellness: true,
    employees: true,
    restaurant: true,
  },
  dependencies: {
    wellness: ['employees'],
    employees: [],
    restaurant: [],
  },
};

export default function FeatureGuard({feature, children}) {
  const {hasFeature} = useContext(UserDetailsContext);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAccess = () => {
      // Vérifie si la feature est définie
      if (!feature) {
        setIsAuthorized(false);
        setIsLoading(false);
        return;
      }

      // Vérifie si la feature est activée globalement
      if (!featuresConfig.features[feature]) {
        setIsAuthorized(false);
        setIsLoading(false);
        return;
      }

      // Vérifie l'accès à la feature pour la company
      const hasMainFeature = hasFeature(feature);

      // Si pas d'accès à la feature principale, pas besoin de vérifier les dépendances
      if (!hasMainFeature) {
        setIsAuthorized(false);
        setIsLoading(false);
        return;
      }

      // Vérifie les dépendances (globales et company)
      const dependencies = featuresConfig.dependencies[feature] || [];
      const hasDependencies = dependencies.every(
          (dep) => featuresConfig.features[dep] && hasFeature(dep),
      );

      // L'accès est autorisé si la feature principale ET toutes les dépendances sont disponibles
      setIsAuthorized(hasMainFeature && hasDependencies);
      setIsLoading(false);
    };

    checkAccess();
  }, [feature, hasFeature]);

  // Pendant le chargement
  if (isLoading) {
    return null;
  }

  // Si non autorisé, affiche la page d'erreur personnalisée
  if (!isAuthorized) {
    return (
      <ViewFeatureError
        feature={feature}
        missingDependencies={featuresConfig.dependencies[feature]?.filter(
            (dep) => !featuresConfig.features[dep] || !hasFeature(dep),
        )}
      />
    );
  }

  // Si autorisé, affiche le contenu
  return children;
}

FeatureGuard.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.string.isRequired,
};
