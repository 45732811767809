import {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

/**
 * Un hook personnalisé pour gérer les onglets avec synchronisation de l'URL.
 * @param {string[]} tabNames Les noms des onglets correspondant aux valeurs dans l'URL.
 * @param {number} defaultTab L'index de l'onglet par défaut.
 * @return {Object} Les états et fonctions pour gérer les onglets et synchroniser avec l'URL.
 */
export function useTabNavigation(tabNames, defaultTab = 0) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(defaultTab);

  // Mettre à jour l'index de l'onglet basé sur l'URL au chargement du composant ou lorsque l'URL change
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    const tabIndex = tabNames.indexOf(tab);

    setTabValue(tabIndex !== -1 ? tabIndex : defaultTab);
  }, [location.search, tabNames, defaultTab]);

  // Fonction pour gérer le changement d'onglet et mettre à jour l'URL en conséquence
  const handleTabChange = (event, newValue) => {
    navigate(`?tab=${tabNames[newValue]}`);
  };

  return {tabValue, handleTabChange};
}
